/*=============================
    Search Overlay 
===============================*/
.search-flyoveray{
    position: fixed;
    background: #080808;
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    transition: all .25s ease-out;
}
.open-cart-aside{
    .search-flyoveray{
        visibility: visible;
        opacity: .7;
    }
}
.cart-flyout{
    position: fixed;
    top: 0;
    width: 420px;
    padding: 50px;
    min-height: 100vh;
    bottom: 0;
    z-index: 111;
    right: 0;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    transition: all .25s ease-in;
    transform: translate(200px,0);
    -webkit-transform: translate(200px,0);
    box-shadow: 0px 0px 87px 0px rgba(0,0,0,.09);
    @media #{$sm-layout} {
        width: 320px;
        padding: 20px;
    }
}

.open-cart-aside{
    .cart-flyout{
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
        -webkit-transform: translate(0,0);
    }
}

.cart-flyout{
    .cart-flyout-inner{
        position: relative;
        .btn-close-cart{
            position: absolute;
            top: 0;
            right: 0;
            font-size: 30px;
            line-height: 30px;
            i{
                color: #535353;
                @extend %transition;
                &:hover{
                    @extend %themecolor;
                }
            }
        }
        .cart-flyout__content{
            padding-top: 50px;
        } 
        .cart-flyout__heading {
            margin-bottom: 40px;
            font-size: 18px;
        } 
        .widget_shopping_cart_content{
            overflow: hidden;
            opacity: 1;
            .product_list_widget{
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: calc(100vh - 430px);
                overflow: auto;
                li {
                    display: flex;
                    position: relative;
                    .thumb{
                        img{
                            max-width: 70px;
                            margin-right: 20px;
                        }
                    }
                    .content{
                        h6{
                            display: block;
                            font-size: 14px;
                            line-height: normal;
                            margin: 0 0 5px;
                            a{
                                color: #2f2f2f;
                                @extend %transition;
                                &:hover{
                                    @extend %themecolor;
                                }
                            }
                        }
                        .quntity{
                            color: #2f2f2f;
                        }
                        .remove-btn{
                            position: absolute;
                            top: 0;
                            right: 0;
                            font-weight: 400;
                            font-size: 14px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            position: absolute;
                            right: 0;
                            text-align: right;
                            width: 20px;
                            padding: 0;
                            border: 0 none;
                            @extend %transition;
                            &:hover{
                                @extend %themecolor;
                            }
                        }
                    }
                }
            }
        }
        .minicart__total{
            font-weight: 400;
            font-size: 14px;
            color: #535353;
            margin-bottom: 35px;
            padding-top: 15px;
            .price{
                font-size: 18px;
                font-weight: 500;
            }
        }

        .cart__btn{
            a{
                width: 100%;
                display: block;
                margin: 10px 0 0;
                text-align: center;
                line-height: 20px;
                padding: 20px;
                transition: all .25s ease-out;
                color: #fff;
                background-color: #2f2f2f;
                font-weight: 700;
                @extend %transition;

                &:hover{
                    background-color: $theme-color;
                }
            }
        }
    }
}
















