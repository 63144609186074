/*========================
 * Button
============================*/

.shop-now-btn {
    button {
        height: 46px;
        padding: 0 40px;
        border: 1px solid #000000;
        border-radius: 500px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 46px;
        transition: 0.4s;

        &:hover {
            color: #fff;
            border: 1px solid $theme-color;
            background: $theme-color;
        }

        i {
            padding-left: 14px;
        }

        &.button-theme {
            color: #fff;
            border: 1px solid $theme-color;
            background: $theme-color;
        }
    }
}

@media #{$md-layout}{
    .brand-list{
        a{
            padding: 20px;
            display: inline-block;
            text-align: center;
            flex-basis: 50%;
        }
    }
}

@media #{$sm-layout}{
    .brand-list{
        a{
            padding: 20px;
            display: inline-block;
            text-align: center;
            flex-basis: 50%;
        }
    }
}

@media #{$large-mobile}{
    .brand-list{
        a{
            padding: 20px;
            display: inline-block;
            text-align: center;
            flex-basis: 100%;
        }
    }
}

/* ========================
    Slider Video Play 
=========================*/

.yu2fvl {
    z-index: 9999;
    top: 0;
}
.yu2fvl-iframe {
    display: block;
    height: 100%;
    width: 100%;
    border: 0;
}
.yu2fvl-overlay {
    z-index: 9998;
    background: #000;
    opacity: 0.8;
}
.yu2fvl-close {
    position: absolute;
    top: 1px;
    left: 100%;
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    background: #f10 !important;
    background-color: #f10 !important;
    color: #ffffff;
    border: 0;
    background: none;
    cursor: pointer;
}

@media #{$md-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }
}

@media #{$sm-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }

    .video {
        h2 {
            color: #ffffff;
            font-size: 32px;
        }

        h4 {
            font-size: 22px;
            line-height: 32px;
        }
    }
}
