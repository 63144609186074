/*=========================
    Contact Form Style One 
===========================*/
.contact__form--1 {
    input {
        font-size: 18px;
        padding: 25px;
        border-style: solid;
        border-width: 1px;
        border-color: #d4d4d4;
        width: 100%;
        line-height: inherit;
        height: auto;
        @extend %block;
        &[type=submit] {
            background-color: #2f2f2f;
            text-decoration: none;
            padding: 30px;
            border-radius: 0;
            @extend %block;
            width: 100%;
            font-size: 14px;
            @extend %fontWeight700;
            @extend %textUpper;
            line-height: 20px;
            color: #fff;
            @extend %transition;
            &:hover {
                @extend %bgthemecolor;
            }
        }
    }
    textarea {
        @extend input;
        height: 200px;
        min-height: 200px;
    }
}

// Address Style 1
.adress__style--1{
    padding: 31% 12%;
    @media #{$md-layout} {
        padding: 8% 4%;
    }
    @media #{$sm-layout} {
        padding: 8% 4%;
    }
    .contact-address{
        @extend %liststyle;
        li{
            @extend %flexbox;
            @extend %align-center;
            span {
                &.address-icon{
                    color: #f53e6a;
                    font-size: 30px;
                }
                &.address-text{
                    padding-left: 15px;
                    font-size: 16px;
                }
            }
            &:not(:first-child){
                margin-top: calc(30px/2);
            }
            &:not(:last-child){
                padding-bottom: calc(30px/2);
            }
        }
    }
}

.social-icons{
    a {
        padding: 0;
        margin-right: 5px;
        cursor: pointer;
        border-radius: 50%;
        font-size: 19px;
        width: 30px;
        height: 30px;
        color: #181818;
        line-height: 31px;
        display: inline-block;
        text-align: center;
        &:hover{
            color: #ffffff;
        }
        &.facebook{
            &:hover{
                background: $facebook;
            }
        }
        &.twitter{
            &:hover{
                background: $twitter;
            }
        }
        &.pinterest{
            &:hover{
                background: $pinterest;
            }
        }
        &.youtube{
            &:hover{
                background: $youtube;
            }
        }
        &.google-plus{
            &:hover{
                background: $google-plus;
            }
        }
    }
    &.bg-white{
        a{
            background-color: #fff;
        }
    }
}
