/*========================
 * Section Title
==========================*/

.section-title{
    .title{
        font-size: 33px;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: -10px;
    }
}

.section-title--2 {
    .title {
        color: #333745;
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

.section-title--3{
    .title{
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 4px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 500;
        margin-top: -10px;
    }
}
