/*--------------------------
    CountDown Style 1
----------------------------*/

.countdown-inner-content{
    h2{
        color: #292323;
        font-size: 50px;
        text-transform: uppercase;
        line-height: 55px;
        margin: 0;
        font-weight: 700;
        letter-spacing: 2px;
        @media #{$sm-layout} {
            font-size: 30px;
            line-height: 47px;
            br{
                display: none;
            }
        }
    }
}

.countbox {
    @media #{$md-layout} {
        text-align: left;
    }
    @media #{$sm-layout} {
        text-align: left;
    }
    .ht-count {
        display: inline-block;
        position: relative;
        margin: 0 5px;

        @media #{$sm-layout} {
            margin: 0 5px;
            margin-bottom: 15px;
        }
        &:last-child::before {
            display: none;
        }
    }
}
.countdown-style-1 {
    .ht-count {
        color: #fff;
        width: 125px;
        @media #{$md-layout} {
            width: 125px;
        }
        .count-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .time-count {
                height: 122px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-size: 50px;
                font-weight: 500;
                position: relative;
                z-index: 1;
                background: #fff;
                color: #9390a0;
                box-shadow: 0 0 52px rgba(0,0,0,0.1);
            }
            p {
                margin: 0;
                height: 50px;
                line-height: 50px;
                font-size: 24px;
                font-weight: 500;
                background: #fff;
                color: #1c1c1c;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: 3px;
                margin-top: 5px;
                text-align: center;
            }
        }
    }
}


@media #{$md-layout}{
    .countdown-area {
        position: relative;
        z-index: 2;
        &::before{
            position: absolute;
            content: "";
            background: #fff;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.3;
        }
    }
}

@media #{$sm-layout}{
    .countdown-area {
        position: relative;
        z-index: 2;
        &::before{
            position: absolute;
            content: "";
            background: #fff;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.3;
        }
    }
}

