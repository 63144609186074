/* Header Default */
.haeder-default {
    .mainmenu__nav {
        ul {
            &.primary-menu {
                @extend %liststyle;
                @include flexbox;
                @include justify-content(center);

                > li {
                    > a {
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-align: center;
                        -moz-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
                        padding: 0 35px;

                        @media #{$laptop-device} {
                            font-size: 16px;
                            font-weight: 700;
                            padding: 0 25px;
                        }
                    }
                }
                > li{
                    > a{
                        padding-top: 45px;
                        padding-bottom: 45px;
                    }
                }
            }
        }
    }
}
