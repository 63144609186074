/*==========================
    Best Rating Product 
=============================*/

.best-rating-product{
    display: flex;
    align-items: center;
    border: 1px solid #f1f1f1;
    .content{
        padding: 25px;
        h6{
            font-size: 18px;
            font-weight: 500;
            margin: 0;
            a{
                color: #434343;
                transition: 0.3s;
                &:hover{
                    color: $theme-color;
                }
            }
        }
        .price{
            span{
                font-size: 18px;
                &.old-price{
                    color: #9e9e9e;
                    font-size: 14px;
                    text-decoration: line-through;
                }
            }
        }
    }
}
