/*========================
Service Area 
==========================*/

.service {
    .title {
        color: #262626;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 16px;
    }
    p{
        color: #262626;
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
    }
}
