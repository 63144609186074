/*========================
    Pagination Area 
=========================*/
.pagination_style {
    list-style: outside none none;
    margin: 70px 0 0;
    padding: 0;
    text-align: center;
    li {
        display: inline-block;
        margin: 0 3px 3px;
        a {
            background-color: #f6f6f6;
            color: #3f3f3f;
            display: inline-block;
            font-size: 13px;
            font-weight: 500;
            height: 40px;
            line-height: 41px;
            width: 40px;
            &:hover,
            &.active {
                background-color: #3f3f3f;
                color: #fff;
            }
        }
    }
}